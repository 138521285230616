import { useContext } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import { IUserRecord } from '../admin-ui.types';
//import { IUserRecord } from '../admin-ui.types';

const buttonStyle = {
    border: '2px solid black',
    backgroundColor: 'lightyellow',
    fontSize: '1.2rem',
    borderRadius: '5px',
    gridColumnStart: 3,
    gridColumnEnd: 4,
    marginLeft: '0rem',
    fontWeight: 'bold',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)',
    justifySelf: 'left',
    paddingLeft: '1rem',
    paddingRight: '1rem',
}

const userRecordFormStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem 15rem 10rem 10rem',
    gridTemplateRows: '2rem 2rem 2rem 2rem 2rem',
    gridGap: '.8rem',
    padding: '20px',
    fontSize: '1.5rem',
    backgroundColor: 'lightyellow',
    paddingTop: '5rem',
}

const displayLabelStyle = {
    justifySelf: 'right', 
    gridColumnStart: 1,
}

const displayValueStyle = {
    justifySelf: 'left', 
    fontWeight: 'normal',
    gridColumnStart: 2,
    gridcolumnEnd: 4,
}

const ACTIVATE_MUTATION = gql`
    mutation ActivateUser($email: String!) {
        activateUser(email: $email) { 
            sts 
        }
}`;

const DEACTIVATE_MUTATION = gql`
    mutation DeacctivateUser($email: String!) {
        deActivateUser(email: $email) { 
            sts 
        }
}`;

interface IUserResponse {
    getUserByEmail: IUserRecord;
}

const GETUSER_QUERY = gql`
    query GetUserByEmail($email: String!) {
        getUserByEmail(email: $email) { 
            email firstName lastName active userType
        }
}`;

const DisplayUser = () => {
    const ctx = useContext(AppContext);
    const variables = { email: ctx.editingUser?.email}
    const sessionid = localStorage.getItem('sessionid');

    const updateUser = async () => {
        try {
            if(sessionid) {
                const requestHeaders = { sessionid };
                const data: IUserResponse = await request({ 
                    url: getGqlUrl(), 
                    document: GETUSER_QUERY, 
                    variables,
                    requestHeaders });
                if(data) {
                    ctx.setCtx && ctx.setCtx({...ctx, editingUser: {...data.getUserByEmail} });
                }
            }
        } catch(err) {
            alert('Bad user??');
        }
    };
    
    const activateHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if(sessionid) {
            const requestHeaders = { sessionid };
            await request({ url: getGqlUrl(), document: ACTIVATE_MUTATION, variables, requestHeaders});
            await updateUser();
        }
    }
    
    const deActivateHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(sessionid) {
            const requestHeaders = { sessionid };
            await request({ url: getGqlUrl(), document: DEACTIVATE_MUTATION, variables, requestHeaders });
            await updateUser();
        }
    }

    return  ( 
        <>
            <form style={userRecordFormStyle}>
                <>
                    <text style={displayLabelStyle}>Email:</text>
                    <text style={displayValueStyle}>{ctx.editingUser?.email}</text>
                </>
                <>
                    <text style={displayLabelStyle}>First Name:</text>
                    <text style={displayValueStyle}>{ctx.editingUser?.firstName}</text>
                </>
                <>
                    <text style={displayLabelStyle}>Last Name:</text>
                    <text style={displayValueStyle}>{ctx.editingUser?.lastName}</text>
                </>
                <>
                    <text style={displayLabelStyle}>Status:</text>
                    <text style={displayValueStyle}> { ctx.editingUser?.userType } </text>
                </>
                <>
                    <text style={displayLabelStyle}>User Type:</text>
                    <text style={ {...displayValueStyle, gridColumnEnd: 3} }> { ctx.editingUser?.active ? 'Active' : 'Not Active' } </text>
                    {
                        ctx.editingUser?.active ? 
                            <button style={buttonStyle} onClick={deActivateHandler}>Deactivate</button>
                            :
                            <button style={buttonStyle} onClick={activateHandler}>Activate</button>
                    }
                </>
            </form>
        </>
    );
}

export default DisplayUser;