import { useContext, useState  } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import { IUserRecord } from '../admin-ui.types';

const buttonStyle = {
    border: '2px solid black',
    backgroundColor: 'lightyellow',
    fontSize: '1.2rem',
    borderRadius: '5px',
    gridColumnStart: 3,
    gridColumnEnd: 4,
    marginLeft: '0rem',
    fontWeight: 'bold',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)'
}

const emailInputFormStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem 20rem 5rem',
    gridTemplateRows: '2rem',
    gridGap: '.8rem',
    padding: '20px',
    fontSize: '1.5rem',
    backgroundColor: 'lightyellow',
    paddingTop: '5rem',
}

interface IUserResponse {
    getUserByEmail: IUserRecord;
}

const GETUSER_QUERY = gql`
    query GetUserByEmail($email: String!) {
        getUserByEmail(email: $email) { 
            email firstName lastName active userType
        }
}`;

const UserEmailInputForm = () => {
    const [userEmail, setUserEmail] = useState('');
    const ctx = useContext(AppContext);

    const userEmailHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const sessionid = localStorage.getItem('sessionid') as string;
            const requestHeaders = { sessionid };
            const vars = { email: userEmail }

            const data: IUserResponse = await request({ 
                url: getGqlUrl(), 
                document: GETUSER_QUERY, 
                variables: vars,
                requestHeaders });

            if(data) { 
                //alert(`user: ${JSON.stringify(data.getUserByEmail)}`);
                ctx.setCtx && ctx.setCtx({...ctx, editingUser: {...data.getUserByEmail} });
            }
        } catch(err) {
            alert('Bad user??');
        }
    }

    return  ( 
        <>
            <form style={emailInputFormStyle}>
                <label style={{ justifySelf: 'right'}}>Email:</label>
                <input type="text" onChange={(e) => setUserEmail(e.target.value)} />
                <button style={buttonStyle} onClick={userEmailHandler}>
                    GO
                </button>
            </form>
        </>
    );
}

export default UserEmailInputForm;