
import { useState, useContext } from'react';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';


//import gql from 'graphql-tag';


import appContext from '../appContext';

const formStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem 20rem',
    gridTemplateRows: '2rem 2rem 2rem',
    gridGap: '.8rem',
    color: 'grey',
    padding: '20px',
    fontSize: '1.5rem',
    backgroundColor: 'lightyellow',
    paddingTop: '5rem',
}

const buttonStyle = {
    border: '2px solid black',
    backgroundColor: 'lightgreen',
    fontSize: '1.2rem',
    borderRadius: '5px',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    marginLeft: '12rem',
    fontWeight: 'bold',
    backgroundImage: 'linear-gradient(lightgrey, #f0f0f0)'
}

const LOGIN_MUTATION = gql`
    mutation LoginUser($loginArgs: LoginArgs) {
        loginUser(loginArgs: $loginArgs) { 
            sts 
            sessionid
        }
}`;

interface ILoginResponse  {
    loginUser: {
        sessionid: string,
        sts: string,
    }
}

const LoginForm =  () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const ctx = useContext(appContext);
    
    const loginHandler = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const vars = { loginArgs: { email, password } }

            const data: ILoginResponse = await request({ url: getGqlUrl(), document: LOGIN_MUTATION, variables: vars });

            if(data) { 
                localStorage.setItem('sessionid', data.loginUser.sessionid);
            }
            ctx.setCtx && ctx.setCtx({...ctx, loggedIn: true, sessionid: data.loginUser.sessionid });
        } catch(err) {
            alert('Bad user/password');
        }
    };

    return (
        <form style={formStyle}>
            <label style={{ justifySelf: 'right'}}>Email:</label>
            <input type="text" onChange={(e) => setEmail(e.target.value)} />
            <label style={{ justifySelf: 'right'}}>Password:</label>
            <input type="text" onChange={(e) => setPassword(e.target.value)} />
            <button style={buttonStyle} onClick={loginHandler}>
                Login
            </button>
        </form>
    )
}

export default LoginForm;

