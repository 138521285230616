
import { useContext } from 'react';
import LoginForm from './loginform';
import UserEmailInput from './userEmailInputForm';
import DisplayUser from './displayUserForm';
import AppContext from '../appContext';

const contentStyle = {
    borderBottom: '2px black solid', 
    backgroundColor: 'lightgreen', 
    fontSize: '25px', 
    fontWeight: 'bold',
    color: '#666',
    display: 'grid',
};

const Content = () => {
    const ctx = useContext(AppContext);
    return (
        <div style={contentStyle}>
            { ctx.loggedIn ? 
                (ctx.editingUser ? 
                    <DisplayUser /> : <UserEmailInput />
                ) 
                :  
                <LoginForm /> }
        </div>
    );
}

export default Content;