
import { Dispatch, SetStateAction, createContext } from 'react';
import { IUserRecord } from './admin-ui.types';

export interface IAppContext {
    loggedIn: boolean;
    sessionid?: string | null;
    editingUser?: IUserRecord | null; // email of the user being manipulated
    setCtx?: Dispatch<SetStateAction<IAppContext>>;
}

export const initialContext : IAppContext = {
    loggedIn: false,
    sessionid: null,
    editingUser: null
}

const AppContext = createContext(initialContext);

export default AppContext;