import { useContext } from 'react';
import AppContext from '../appContext';
import { gql, request } from 'graphql-request';
import { getGqlUrl } from '../index';
import homeImage from '../home.png';


const headerStyle = {
    borderBottom: '2px black solid', 
    fontSize: '25px', 
    fontWeight: 'bold',
    color: '#666',
    display: 'grid',
    gridTemplateColumns: '64px 1fr 1fr'
}

const logoutButtonStyle = {
    //border: '1px solid black',
    border: 'none',
    borderRadius: '5px',
    justifySelf: 'right',
    marginRight: '20px',
    alignSelf: 'center',
    fontWeight: 'bold',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '3px',
    paddingTop: '3px',
    backgroundColor: '#ccc', 
}

const LOGOUT_MUTATION = gql`
    mutation {
        logoutUser
    } 
`;

const Header = () => {
    const ctx = useContext(AppContext);

    const logoutHandler = async () => {
        try {
        const sessionid = localStorage.getItem('sessionid');
            if(sessionid) {
                const requestHeaders = { sessionid };
                await request({ url: getGqlUrl(), document: LOGOUT_MUTATION, requestHeaders });
                localStorage.removeItem('sessionid');
            }
        } catch(err: any) {
            console.log(`Logout Error: ${err}`)
        } finally {
            ctx.setCtx && ctx.setCtx({...ctx, loggedIn: false, sessionid: null});
        }
    }

    const goHomeHandler = async () => {
        ctx.setCtx&& ctx.setCtx({...ctx, editingUser: null});
    }

    return (
        <div style={headerStyle}>
            <img  src={homeImage} alt='' width='64' height='64' onClick={goHomeHandler} />
            <text style={{ marginLeft: '30px', marginTop: '15px' }}>Gosachi Admin Dashboard</text>
            <>
                { ctx.loggedIn ? 
                        <text style={logoutButtonStyle} onClick={logoutHandler}>
                            Logout
                        </text> 
                    :
                        ''
                }
            </>
        </div>
    );
}


export default Header;
