import { useState }from 'react';

import './App.css';
import Footer from './components/footer';
import Header from './components/header';
import Content from './components/content';
import AppContext, { initialContext, IAppContext } from './appContext';

const appStyle =  {
  display: 'grid',
  gridTemplateRows: '70px 1fr 50px',
  height: '100vh',
}

const App  = () : JSX.Element => {

  // check if the user is logged in
  const sid = localStorage.getItem('sessionid');
  if(sid) {
    initialContext.sessionid = sid;
    initialContext.loggedIn = true;
  }
  
  const [ctx, setCtx ] = useState<IAppContext>(initialContext);
  
  return (
    <>
      <AppContext.Provider value={{...ctx, setCtx}} >
        <div style={appStyle}>
          <Header />
          <Content />
          <Footer />
        </div>
      </AppContext.Provider>
    </>
  );
}

export default App;
